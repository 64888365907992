.option {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option > .price {
  font-size: 14px;
  padding-left: 12px;
}

.text-option {
  background: var(--theme-surface);
  border: 1px solid var(--theme-surface-border);
  padding: 12px 20px;
  margin-bottom: 12px;
  border-radius: 25px;
  width: 100%;
  box-sizing: border-box;
  text-transform: capitalize;
  transition: all .25s ease-in-out;
}
.text-option > span:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-option.active {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 2px var(--theme-primary);
  font-weight: 500;
}

.image-option {
  width: 75px;
  height: 75px;
  padding: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 12px;
  background: var(--theme-surface);;
  border: 2px solid var(--theme-surface-border);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-option.active {
  border: 2px solid var(--theme-primary);
}
.image-option > img {
  max-width: 100%;
  max-height: 100%;
}

.color-option {
  width: 48px;
  height: 48px;
  padding: 4px;
  margin-right: 0px;
  margin-bottom: 8px;
  border-radius: 50%;
  background: transparent;
  box-sizing: border-box;
}
.color-option.active {
  border: 2px solid var(--theme-primary);
}
.color-option > div {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--theme-border);
  box-sizing: border-box;
}
.color-option > div.white {
  background: #767273;
}
.color-option > div.black {
  background-color: rgb(96,39,23);
}
.color-option > div.silver {
  background-color: #26211e
}
.color-option > div.blue {
  background-color: #ceae8a
}
.color-option > div.red {
  background-color: #ffffff;
}

@media(min-width: 576px) {
  .color-option {
    width: 65px;
    height: 65px;
  }
  .image-option {
    width: 100px;
    height: 100px;
  }
}
@media(min-width: 992px) {
  .text-option {
    max-width: 300px;
  }
}
.settings {
  padding: 32px;
  box-sizing: border-box;
  text-align: center;
}

.settings-group {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.settings-group > h3 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 16px;
}

.settings-group-disclaimer {
  font-size: 14px;
  line-height: 24px;
  opacity: var(--theme-medium-opacity);
}

.settings-options {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.settings-options .settings-options-text {
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.settings-group-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settings-group-label > span:first-child {
  padding-right: 12px;
}
.settings-group-label > span:last-child {
  padding-left: 12px;
}
.settings-group-label > .price {
  font-size: 14px;
  text-transform: capitalize;
}

.settings-group-benefits > p {
  font-weight: 500;
}
.settings-group-benefits > ul {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
.settings-group-benefits > ul > li {
  margin-bottom: .6em;
}

@media(min-width: 992px) {
  .settings {
    flex: 1;
    overflow-y: auto;
    min-width: 435px;
    padding: 48px;
    text-align: left;
  }
  .settings-group-label {
    justify-content: space-between;
  }
  .settings-options {
    justify-content: flex-start;
  }
  .settings-options .settings-options-text {
    flex-direction: row;
    flex-wrap: wrap;
  }
}